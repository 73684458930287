import React from 'react';
import Header from './header';

import './styles.scss';
import './layout.scss';

const Layout = ({ children, className }: any) => {
  return (
    <div className={`mcd-layout ${className ? className : ''}`}>
      <Header />
      <main>{children}</main>
      <footer>© {new Date().getFullYear()} Brian McDaniel</footer>
    </div>
  );
};

export default Layout;
