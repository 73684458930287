import React from 'react';
import { Link } from 'gatsby';
import './header.scss';

const Header = () => (
  <header className="mcd-header">
    <div className="header-container">
      <Link to="/">home</Link> | <Link to="/posts/">posts</Link>
    </div>
  </header>
);

export default Header;
