import React from 'react';
import { graphql, Link } from 'gatsby';

import Layout from '../components/layout';
import './posts.scss';

const PostsPage = ({ data }: any) => {
  const posts = data.allMarkdownRemark.edges;
  return (
    <Layout className="mcd-posts">
      {posts.map(({ node }: any) => (
        <PostCard key={node.id} post={node} />
      ))}
    </Layout>
  );
};

export default PostsPage;

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fields: { draft: { eq: false } } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 5
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`;

function PostCard({ post }: any) {
  return (
    <div className="mcd-post-card">
      <h3>
        <Link to={`/${post.fields.slug}`}>{post.frontmatter.title}</Link>
      </h3>
      <p>{post.excerpt}</p>
      <small>{post.frontmatter.date}</small>
    </div>
  );
}
